<template>
  <router-link :to="{ name: 'category-open', params: { link: data.link } }" class="category-card">
    <div class="ratio-container">
      <ImgComponent class="category-card__img" :img="data.site_square_img" width="600" height="400" />
    </div>
    <div class="category-card__info">
      <span class="category-card__title"> {{ data.title }} </span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "CategoryCardComponent",
  props: {
    data: Object,
  },
  components: { ImgComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.category-card {
  ratio(312, 214)
  display flex
  flex-direction column
  align-items center
  background: var(--white);
  border-radius: 16px;
  gap 20px
  transition var(--transition)
  cursor pointer

  &:hover {
    filter brightness(0.9)
  }

  &__img {
    width: 120px;
    height: 120px;
    border-radius: 16px;
  }

  &__info {
    absolute top right bottom left
    padding 24px
    +below(480px) {
      padding 10px
    }
  }

  &__title {
    display inline-flex
    line-height: 22px;
    color: var(--gray-900);
    +below(480px) {
      font-size .875em
      line-height 1.3
    }
  }
}
</style>
