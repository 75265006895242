<template>
  <component
    :is="tag"
    v-if="data"
    :to="{ name: route, params: { link: data.link }, query }"
    :href="data.link"
    class="catalog-banner"
  >
    <span class="ratio-container">
      <ImgComponent v-if="data.src" :src="data.src" />
      <ImgComponent v-else :img="data.site_rect_img || data.img" width="1000" height="1000" />
      <div class="catalog-banner__info">
        <span class="catalog-banner__title">{{ data.title }}</span>
      </div>
    </span>
  </component>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";

export default {
  name: "CatalogBannerComponent",
  components: { ImgComponent },
  props: {
    data: Object,
    query: Object,
    isLink: Boolean,
  },
  computed: {
    tag() {
      return this.isLink ? "a" : "router-link";
    },
    route() {
      if (this.data && this.data.child_categories && this.data.child_categories.length) {
        return "category-main";
      }
      return "category-open";
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.catalog-banner {
  ratio(1, 1)
  border-radius: 32px;
  overflow: hidden;

  &:hover {
    filter brightness(0.9)
  }

  &__info {
    absolute left top right bottom
    padding 32px
    color var(--gray-900)
    font-weight: 500;
    font-size: 1.25em
    line-height: 32px;
  }
}
</style>
