<template>
  <div v-if="data && data.length" class="sets-grid" :class="{ 'sets-grid--reverse': reverse }">
    <SetsItemCard v-for="(item, i) in data" :key="i" :data="item" />
  </div>
</template>

<script>
import SetsItemCard from "./SetsItemCard.vue";
export default {
  name: "SetsGridComponent",
  props: {
    reverse: Boolean,
    data: {
      type: Array,
    },
  },
  components: { SetsItemCard },
};
</script>

<style lang="stylus">
.sets-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap 20px
  +below(1024px) {
    //grid-template-columns: repeat(2, 1fr);
  }
  :nth-child(1) {
    grid-area: 1 / 1 / 3 / 3;
    +below(1024px) {
     grid-area: 1/1/1/5;
    }
    .img{
      height: 656px;
      +below(1024px) {
        height: 158px;
      }
    }
  }
  :nth-child(2) {
    grid-area: 1 / 3 / 2 / 4;
    +below(1024px) {
      grid-area: 2/1/3/3;
    }
  }
  :nth-child(3) {
    grid-area: 1 / 4 / 2 / 5;
    +below(1024px){
      grid-area: 2/3/3/5;
    }
  }
  :nth-child(4) {
    grid-area: 2 / 3 / 3 / 5;
    +below(1024px) {
      grid-area: 3/5/3/1;
    }
  }

  &--reverse{
    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 2;
      +below(1024px) {
        grid-area: 1/1/3/3;
      }
      .img{
        height: 312px;
        +below(1024px) {
          height: 158px;
        }
      }
    }
    :nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
      +below(1024px) {
        grid-area: 1/3/1/5;
      }
    }
    :nth-child(3) {
      grid-area: 2 / 1 / 3 / 3;
      +below(1024px){
        grid-area: 2/1/3/3;
      }
    }
    :nth-child(4) {
      grid-area: 1 / 3 / 3 / 5;
      +below(1024px) {
        grid-area: 2/3/3/5;
      }
      .img{
        height: 656px;
        +below(1024px) {
          height: 158px;
        }
      }
    }
  }
}
</style>
