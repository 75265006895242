<template>
  <main class="page catalog-page">
    <div class="page__inner">
      <SectionComponent>
        <h2>Каталог товаров</h2>
        <div class="catalog-page__banners">
          <CatalogBannerComponent :data="banner" is-link />
          <CatalogBannerComponent
            :data="{ title: 'Акции', src: '/static/images/discount_catalog.png' }"
            :query="{ discount: true }"
          />
          <CatalogBannerComponent :data="important_category" />
        </div>
      </SectionComponent>
      <CookLinksComponent :list="cookLinks" is-catalog />
      <SectionComponent>
        <SetsGridComponent :data="important_categories" :reverse="true" route="category-main" />
        <SliderComponent
          class="category-list__slider"
          :items="discount_products.data"
          v-slot="{ item, grid }"
          :slider-options="sliderSaleOptions"
          title="Акции"
          :route="{ name: 'category-open', query: { discount: true } }"
          button-title="Все товары"
        >
          <CardComponent :data="item" :grid="grid" />
        </SliderComponent>
        <!--        <BrandsComponent :list="brands" />-->
        <div class="categories-section">
          <SetsCategoryTabsComponent category :data="parentCategories" />
          <div class="categories-section--right">
            <SetsCategoryComponent
              v-for="(item, i) in parentCategories"
              :key="i"
              :title="item.title"
              :link="item.link"
              :id="item.link"
            >
              <CategoryCardComponent v-for="(child, ind) in item.child_categories" :key="ind" :data="child" />
            </SetsCategoryComponent>
          </div>
        </div>
      </SectionComponent>
    </div>
  </main>
</template>

<script>
import SetsGridComponent from "../sets/components/SetsGridComponent.vue";
import CookLinksComponent from "components/CookLinksComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import CardComponent from "components/CardComponent.vue";
import SetsCategoryTabsComponent from "../sets/components/SetsCategoryTabsComponent.vue";
import CategoryCardComponent from "./components/CategoryCardComponent.vue";
import SetsCategoryComponent from "../sets/components/SetsCategoryComponent.vue";
import BrandsComponent from "./components/BrandsComponent.vue";
import CATALOG_PAGE_STORE from "@/graphql/pages/store/CatalogPage.graphql";
import CATALOG_PAGE_LANDING from "@/graphql/pages/landing/CatalogPage.graphql";
import CatalogBannerComponent from "./components/CatalogBannerComponent.vue";
import { moreButtonTitle } from "@/utils";

export default {
  name: "CatalogPage",
  async asyncData({ apollo, store }) {
    let token = store.state.auth_token;
    await apollo.clients.store
      .query({
        query: CATALOG_PAGE_STORE,
        variables: {},
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        store.state.catalog_page.parent_categories = data.parent_categories;
        store.state.catalog_page.brands = data.brands_paginate?.data || [];
        store.state.catalog_page.important_categories = data.important_categories || [];
        store.state.catalog_page.discount_products = data.discount_products;
      });
    await apollo.clients.landing
      .query({
        query: CATALOG_PAGE_LANDING,
        variables: {},
      })
      .then(({ data }) => {
        store.state.catalog_page.cook_links = data.cook_links;
        if (data.banners_paginate.data) store.state.catalog_page.banner = data.banners_paginate.data[0];
      });
  },
  data() {
    return {
      first: 10,
      sliderSaleOptions: {
        slidesPerView: 1.1,
        spaceBetween: 10,
        grid: {
          rows: 2,
        },
        breakpoints: {
          540: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 2.1,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  computed: {
    discount_products() {
      return this.$store.state.catalog_page.discount_products;
    },
    brands() {
      return this.$store.state.catalog_page.brands;
    },
    parentCategories() {
      return this.$store.getters.parent_categories;
    },
    cookLinks() {
      return this.$store.state.catalog_page.cook_links;
    },
    banner() {
      return this.$store.state.catalog_page.banner;
    },
    // первый из важных категорий
    important_category() {
      let categories = JSON.parse(JSON.stringify(this.$store.state.catalog_page.important_categories));
      return categories.shift();
    },
    // все важные категории, кроме первого
    important_categories() {
      let categories = JSON.parse(JSON.stringify(this.$store.state.catalog_page.important_categories));
      categories.shift();
      return categories;
    },
  },
  methods: { moreButtonTitle },
  components: {
    CatalogBannerComponent,
    BrandsComponent,
    CategoryCardComponent,
    SectionComponent,
    CookLinksComponent,
    SetsGridComponent,
    SliderComponent,
    CardComponent,
    SetsCategoryTabsComponent,
    SetsCategoryComponent,
  },
  metaInfo: {
    title: "Каталог",
  },
};
</script>

<style lang="stylus">
.catalog-page {
  h2 {
    margin 0
  }

  &__banners {
    display grid
    grid-template-columns 1fr 1fr 1fr
    grid-gap 24px

    +below(628px) {
      grid-template-columns 1fr
    }
  }
}
.category-list__slider{
  height: 474px;
  +below(1200px) {
    height 420px
  }
}
</style>
