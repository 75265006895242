<template>
  <ul v-if="list && list.length" class="brands">
    <li v-for="(item, i) in list" :key="i">
      <router-link class="brands__item" :to="{ name: 'catalog' }">
        <ImgComponent :img="item.img" width="300" height="200" contain />
      </router-link>
    </li>
  </ul>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "BrandsComponent",
  components: { ImgComponent },
  props: {
    list: Array,
  },
};
</script>

<style lang="stylus">
.brands {
  display grid
  grid-template-columns repeat(6, 1fr)
  grid-gap 24px
  +below(1280px) {
    grid-template-columns repeat(4, 1fr)
  }
  +below(820px) {
    grid-template-columns repeat(3, 1fr)
  }
  +below(620px) {
    grid-template-columns repeat(2, 1fr)
  }
  +below(480px) {
    grid-gap 10px
  }

  &__item {
    display flex
    padding 10px
    border-radius: var(--big-radius);
    background var(--gray-50)
    height 100px
    +below(480px) {
      height 80px
    }

    &:hover {
      background var(--green-50)
    }
  }
}
</style>
