<!--отрефакторить этот компонент и все где он используется-->
<template>
  <router-link :to="{ name: 'sets-group', params: { id: data.id } }" class="sets-item">
    <ImgComponent :img="data.img" />
    <div class="sets-item__title">
      <span>{{ data.title }}</span>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "SetsItemCard",
  components: { ImgComponent },
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
.sets-item {
  position: relative;
  width: 100%
  height: 100%
  display flex

  &:hover {
    filter brightness(0.9)
  }

  img{
    border-radius: 32px;
    height: 312px;

    +below(1024px) {
      border-radius 15px
      height: 158px;
    }
  }
  &__title {
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--gray-900);
    padding: 32px;
    white-space: break-spaces;
    +below(1024px){
      font-size: 0.875em;
      line-height: 20px;
      padding 10px
    }
  }
}
</style>
