<template>
  <section class="sets-category-items">
    <span class="sets-category-items__title">
      {{ title }}
    </span>
    <div class="sets-category-items__grid">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "SetsCategoryComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus">
.sets-category-items{
  display flex
  flex-direction column
  gap 24px
  padding-top: var(--header-main-height);
  margin-top: calc(-1 * var(--header-main-height));

  &__title{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: var(--gray-dark);

    &:hover {
      //color var(--main)
    }
  }

  &__grid{
    display grid
    grid-template-columns repeat(3, 1fr)
    gap 24px
    +below(1200px) {
      grid-template-columns repeat(2, 1fr);
    }
    +below(1024px) {
      grid-template-columns repeat(3, 1fr);
    }
    +below(860px) {
      grid-template-columns repeat(2, 1fr);
    }
    +below(540px) {
      gap: 10px
    }
    +below(360px) {
      grid-template-columns 1fr
    }
  }
}
</style>
