<template>
  <div class="sets-category-tabs">
    <a
      :href="item.link ? `#${item.link}` : `#${item.id}`"
      v-for="(item, i) in data"
      :key="i"
      class="sets-category-tabs__item"
    >
      <div class="sets-category-tabs__left">
        <ImgComponent v-if="item.site_square_img" :img="item.site_square_img" />
        <span>{{ item.title }}</span>
      </div>
      <RemixIconComponent category="system" name="arrow-right-s-line" />
    </a>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "SetsCategoryTabsComponent",
  components: { ImgComponent, RemixIconComponent },
  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style lang="stylus">
.sets-category-tabs{
  display flex
  flex-direction column
  gap 8px
  height: fit-content;
  z-index 1
  +above(1025px) {
    position sticky
    top 100px
    max-height calc(100vh - var(--header-main-height))
    overflow auto
  }
  +below(1024px) {
    max-height 400px
    overflow-y auto
  }

  &__item{
    flex-shrink 0
    display flex
    justify-content space-between
    padding 16px 12px
    background-color var(--white)
    border: 1px solid var(--border-color-2);
    border-radius: 16px;
    transition var(--transition)
    cursor pointer
    span{
      font-weight: 400;
      font-size: 0.875em;
      line-height: 20px;
      color: var(--gray-800);
    }
    svg{
      width: 20px;
      height: 20px;
    }
    &:hover{
      border: 1px solid var(--main);
    }
  }
  &__left{
    display flex
    gap 4px
    align-items: center;
    img{
      width: 28px;
      height: 28px;
      border-radius: 16px;
    }
  }
}
</style>
